import { useNavigate } from "react-router";
import Spinner from "../../components/Spinner";
import { InboxIcon } from "@heroicons/react/24/solid";
import { Role, Survey } from "../../types";
import SurveyList from "../../components/SurveyList";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";

export default function Assessments() {
  const navigate = useNavigate();
  const { getApiData } = useApi();

  const { isPending: loading, data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys"],
    queryFn: () => getApiData(`/strata/surveys`),
    initialData: [],
  });

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div className="flex-grow w-full max-w-full sm:max-w-7xl mx-auto xl:px-8 lg:flex">
        <div className="flex-1 min-w-0 bg-white">
          <div className="xl:flex-shrink-0 xl:border-gray-200 bg-white">
            <div className="pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
              <div className="flex items-center justify-between">
                <div className="flex-1 space-y-8">
                  <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                    <div className="flex items-center space-x-2">
                      <InboxIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="text-sm text-gray-500 font-medium">{surveys.length} Assessments</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <SurveyList surveys={surveys} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
