export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export default async function apiGet(endpoint: string, auth: string | undefined) {
  const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth ?? "",
    },
  });
  const body = await response.json();

  if (body.name === "TokenExpiredError") {
    window.location.reload();
  }

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return body;
}

export function getInitialsFromName(name: string): string {
  return name.split(" ").length > 1 ? name.split(" ")[0][0] + name.split(" ").slice(-1)[0][0] : name.split(" ")[0][0];
}
