import { Link, useNavigate } from "react-router-dom";
import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import MonthSelect from "./MonthSelect";
import { useContext, useEffect, useState } from "react";
import YearSelect from "./YearSelect";
import CompanySelect from "./CompanySelect";
import Button from "./Button";
import { AuthContext } from "../context/authContext";
import { Role } from "../types";
import { PlusIcon } from "@heroicons/react/24/solid";

export default function SurveyList(props: { surveys: Array<any>; hideCompanySelect?: boolean }) {
  let { surveys, hideCompanySelect } = props;

  const [surveyList, setSurveyList] = useState<Array<any>>([]);
  const [filteredMonth, setFilteredMonth] = useState<number | null>(null);
  const [filteredYear, setFilteredYear] = useState<number | null>(null);
  const [filteredCompany, setFilteredCompany] = useState<number | null>(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let filteredSurveys = surveys;
    if (filteredMonth !== null) {
      filteredSurveys = filteredSurveys.filter((s) => new Date(s.end_date).getMonth() === filteredMonth);
    }
    if (filteredYear !== null) {
      filteredSurveys = filteredSurveys.filter((s) => new Date(s.end_date).getFullYear() === filteredYear);
    }
    if (filteredCompany !== null) {
      filteredSurveys = filteredSurveys.filter((s) => s.company_id === filteredCompany);
    }
    setSurveyList(filteredSurveys);
  }, [surveys, filteredMonth, filteredYear, filteredCompany]);

  surveys.sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime());

  return (
    <ul className="flex flex-col">
      <div className="flex justify-between mb-4 overflow-scroll">
        <div className="flex">
          <MonthSelect setMonth={setFilteredMonth} showAllOption={true} />
          <YearSelect setYear={setFilteredYear} fromYear={Math.min(...surveys.map((s) => new Date(s.end_date).getFullYear()))} toYear={new Date().getFullYear()} showAllOption={true} />
          {!hideCompanySelect && <CompanySelect setCompanyId={setFilteredCompany} showAllOption={true} />}
        </div>
        {auth.role === Role.Manager && <Button text="Create Assessment" className="mt-5" onClick={() => navigate("/assessments/create")} icon={PlusIcon} />}
      </div>
      {surveyList.length === 0 ? (
        <div className="text-center mt-4">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No Assessments</h3>
          <p className="mt-1 text-sm text-gray-500">Try changing your filter selections</p>
        </div>
      ) : (
        surveyList.map((survey: any, index: number) => (
          <li key={survey.survey_id} className="bg-white shadow border overflow-hidden my-1 rounded px-6 py-1 hover:bg-gray-50 w-11/12 lg:w-full mt-2">
            <Link to={`/assessment/${survey.survey_id}`}>
              <div className="py-2 sm:px-2">
                <div className="sm:flex items-center justify-between">
                  <div className="sm:flex-auto sm:w-3/4">
                    <p className="text-sm font-bold text-gray-500 truncate">{survey.survey_name}</p>
                    <p className="text-sm text-gray-400 truncate">
                      {new Date(survey.start_date).toLocaleDateString()} - {new Date(survey.end_date).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 flex-initial sm:w-1/4">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                    {new Date() < new Date(survey.start_date) ? (
                      <p className="text-gray-500">
                        Starting on <time dateTime={survey.start_date}>{new Date(survey.start_date).toLocaleString("en-US")}</time>
                      </p>
                    ) : new Date() > new Date(survey.start_date) && new Date(survey.end_date) > new Date() ? (
                      <p className="text-green-500">
                        In Progress - Closing on <time dateTime={survey.end_date}>{new Date(survey.end_date).toLocaleString("en-US")}</time>
                      </p>
                    ) : (
                      <p className="text-red-500">
                        Closed on <time dateTime={survey.end_date}>{new Date(survey.end_date).toLocaleString("en-US")}</time>
                      </p>
                    )}
                  </div>
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 hidden sm:block" aria-hidden="true" />
                </div>
              </div>
            </Link>
          </li>
        ))
      )}
    </ul>
  );
}
