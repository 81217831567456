import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { Switch } from "@headlessui/react";
import apiGet from "../libs/helpers";
import Button from "./Button";
import Spinner from "./Spinner";
import { Role } from "../types";
import { Link } from "react-router-dom";
import Alert from "./Alert";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

export default function SubscriptionInfo(props: { company: Company }) {
  const { company } = props;
  const paymentInfoId = company.payment_info_id;

  const auth = useContext(AuthContext);
  const [showExisting, setShowExisting] = useState<boolean>(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState<number>();
  const [selectedExistingPaymentInfoId, setSelectedExistingPaymentInfoId] = useState<number>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { data: paymentInfo, isPending: paymentInfoIsPending } = useQuery<PaymentInfo>({
    queryKey: ["paymentInfo", paymentInfoId, auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/payment-info/${paymentInfoId}`, auth.sessionInfo?.idToken),
    enabled: !!paymentInfoId,
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies", auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/companies`, auth.sessionInfo?.idToken),
    initialData: [],
  });

  const { data: pointOfContacts } = useQuery<PointOfContact[]>({
    queryKey: ["pointOfContacts", company.company_id, auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/pointOfContacts/company/${company.company_id}`, auth.sessionInfo?.idToken),
    enabled: !!company,
  });

  const mainPOC = pointOfContacts?.find((p) => p.poc_type_id === 1);

  async function createPaymentInfo() {
    setSubmitting(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/payment-info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionInfo?.idToken ?? "",
      },
      body: JSON.stringify({ price: subscriptionPrice, companyId: company.company_id }),
    });

    if (response.status === 200) {
      window.location.reload();
    }
    console.error(response);
    setSubmitting(false);
  }

  async function associatePaymentInfo() {
    setSubmitting(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/companies/${company.company_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.sessionInfo?.idToken ?? "",
      },
      body: JSON.stringify({ payment_info_id: selectedExistingPaymentInfoId }),
    });

    if (response.status === 200) {
      window.location.reload();
    }
    console.error(response);
    setSubmitting(false);
  }

  if (paymentInfoId && paymentInfoIsPending) {
    return <Spinner />;
  }

  return (
    <div>
      {paymentInfo ? (
        <div>
          <div className="mt-6 border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Payment Info Id</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{paymentInfo.payment_info_id}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Stripe Customer Id</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{paymentInfo.stripe_customer_id}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Stripe Subscription Id</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{paymentInfo.stripe_subscription_id}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Stripe Subscription Status</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{paymentInfo.stripe_subscription_status}</dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Stripe Price Id</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{paymentInfo.stripe_price_id}</dd>
              </div>
            </dl>
          </div>
        </div>
      ) : (
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-red-500">No Payment Info Found</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Either add existing or create new payment info</p>
          </div>
          {auth.role === Role.Manager && (
            <div>
              <div className="flex items-center mt-5">
                <Switch
                  checked={showExisting}
                  onChange={setShowExisting}
                  className={`group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent ${
                    showExisting ? "bg-indigo-600" : "bg-gray-200"
                  } transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                >
                  <span
                    aria-hidden="true"
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${showExisting ? "translate-x-5" : ""}`}
                  />
                </Switch>
                <label className="ml-3 text-sm">
                  <span className="font-medium text-gray-900">Link to existing payment info</span>
                </label>
              </div>
              <div className="mt-6 border-t border-gray-100 w-full">
                <dl className="divide-y divide-gray-100">
                  {showExisting ? (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Exisiting subscription</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {" "}
                        <label className="block text-sm font-medium leading-6 text-gray-900">Link exisiting payment info</label>
                        <div className="mt-2">
                          <select
                            value={selectedExistingPaymentInfoId}
                            onChange={(e) => setSelectedExistingPaymentInfoId(parseInt(e.target.value))}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option>--Choose a company--</option>
                            {companies
                              .filter((c) => c.payment_info_id && c.is_billing)
                              .map((company, index) => (
                                <option key={index} value={company.payment_info_id}>
                                  {company.company_name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </dd>
                    </div>
                  ) : (
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">New Subscription</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">Monthly Subscription Price</label>
                          <div className="mt-2">
                            <input
                              type="number"
                              placeholder="Monthly price"
                              value={subscriptionPrice}
                              onChange={(e) => setSubscriptionPrice(parseInt(e.target.value))}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </dd>
                    </div>
                  )}
                </dl>
                {!showExisting && !mainPOC && (
                  <Alert
                    type="Warning"
                    icon={ExclamationTriangleIcon}
                    title="No Main Point of Contact Added"
                    message={
                      <div>
                        Please{" "}
                        <Link to={`/company/${company.company_id}?tab=pointOfContact`} className="font-bold underline">
                          add a main point of contact
                        </Link>{" "}
                        before adding a new subscription (Stripe uses the main point of contact as the customer)
                      </div>
                    }
                    className="mb-5"
                  />
                )}
                <Button
                  text={showExisting ? "Link Subscription" : "Create Subscription"}
                  disabled={!showExisting && !mainPOC}
                  onClick={showExisting ? associatePaymentInfo : createPaymentInfo}
                  submitting={submitting}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
