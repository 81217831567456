import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { Link } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <div className="pt-10 relative overflow-hidden flex items-center justify-center">
      <Formik
        initialValues={{
          email: "",
          password: "",
          all: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = "Email is required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.password) {
            errors.password = "Password is required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          try {
            await auth.signInWithEmail(values.email, values.password);
            navigate("/dashboard");
          } catch (err: any) {
            console.error(err);
            if (err.code === "NewPasswordRequired") {
              //auth.sendCode(values.email);
              navigate("/registration");
            }
            else if (err.code === "UserNotConfirmedException") {
              navigate("verify");
            } else {
              setErrors({ all: err.message });
            }
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <div className="items-center justify-center min-h-screen w-6/12 rounded-sm">
            <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-md">
              <Form>
                <h3 className="text-2xl font-bold text-center">
                  Login to your account
                </h3>
                <div className="mt-4">
                  <label className="block" htmlFor="email">
                    Email
                  </label>
                  <Field
                    type="text"
                    name="email"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="email"
                    component="div"
                  />
                </div>
                <div className="mt-4">
                  <label className="block">Password</label>
                  <Field
                    type="password"
                    name="password"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    className="text-red-500 text-xs"
                    name="password"
                    component="div"
                  />
                </div>
                <ErrorMessage
                  className="text-red-500 text-sm mt-5"
                  name="all"
                  component="div"
                />
                <div className="flex items-baseline justify-between mt-5">
                  <Button
                    text="Login"
                    type="submit"
                    submitting={isSubmitting}
                  />
                  <Link
                    to="/forgot-password"
                    className="text-sm text-sky-400 hover:underline"
                  >
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
      {/* <SideDecorations /> */}
    </div>
  );
}
