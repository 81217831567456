import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Spinner from "../../components/Spinner";
import { useQuery } from "@tanstack/react-query";
import colors from "tailwindcss/colors";
import useApi from "../../hooks/useApi";
import { Survey, Respondent, SurveyKey, Dataset, Group, StrataJobTitle } from "../../types";
import Divider from "../Divider";
import RespondentCategory from "./RespondentCategory";
import Container from "../Container";
import RespondentDistribution from "./RespondentDistribution";
import GroupCategoryComparison from "./GroupCategoryComparision";
import TrendChart from "./TrendChart";
import GroupImpairedCategory from "./GroupImpairedCategory";
import Dropdown from "../Dropdown";

function calculateSIValue(respondents: Respondent[]): number {
  const UPPER_DISENGAGED_CUTOFF = 10; // anything at or below is disengaged
  const LOWER_BOUND = 20;
  const UPPER_BOUND = 30;

  let validMentalHealthSum = 0;
  let validMentalHealhtCount = 0;
  for (const respondent of respondents) {
    const mentalHealthValue = parseFloat(respondent.mental_health_value);

    // individuals with mental health score of 10 or less are classified as "disengaged" and should not be included in the overal mental health value
    if (mentalHealthValue > UPPER_DISENGAGED_CUTOFF) {
      validMentalHealthSum = validMentalHealthSum + mentalHealthValue;
      validMentalHealhtCount++;
    }
  }

  const averageMentalHealth = validMentalHealthSum / validMentalHealhtCount;

  // linear transformation
  const rawValue = ((averageMentalHealth - LOWER_BOUND) * 100) / (UPPER_BOUND - LOWER_BOUND);

  // take the inverse of raw value since mental health value is reverse scored (si value goes from 0 - 100 bad to good)
  let siValue = 100 - rawValue;

  // si value cannot be over 100 or less than 0
  if (siValue > 100) {
    siValue = 100;
  } else if (siValue < 0) {
    siValue = 0;
  }

  return siValue;
}

export default function Report(props: { survey: Survey }) {
  const { survey } = props;
  const { getApiData } = useApi();

  const surveyId = survey.survey_id;

  const [selectedStrataJobTitleFilters, setSelectedStrataJobTitleFilters] = useState<number[]>([]);
  const [selectedGroupFilters, setSelectedGroupFilters] = useState<number[]>([]);
  const [showCustomGroups, setShowCustomGroups] = useState<boolean>(true);

  function handleCheckboxChange(event: { target: { id: any; checked: any } }) {
    const { id, checked } = event.target;
    if (showCustomGroups) {
      if (checked) {
        // Add the checkbox to the selected list
        setSelectedGroupFilters((prevSelected) => [...prevSelected, parseInt(id)]);
      } else {
        // Remove the checkbox from the selected list
        setSelectedGroupFilters((prevSelected) => prevSelected.filter((item) => item !== parseInt(id)));
      }
    } else {
      if (checked) {
        // Add the checkbox to the selected list
        setSelectedStrataJobTitleFilters((prevSelected) => [...prevSelected, parseInt(id)]);
      } else {
        // Remove the checkbox from the selected list
        setSelectedStrataJobTitleFilters((prevSelected) => prevSelected.filter((item) => item !== parseInt(id)));
      }
    }
  }

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", survey.company_id],
    queryFn: async () => getApiData(`/strata/surveys/company/${survey.company_id}`),
    initialData: [],
  });

  // Get all respondents for a company no matter the survey
  const { isPending: allRespondentsIsPending, data: allRespondents } = useQuery<Respondent[]>({
    queryKey: ["allRespondents", survey.company_id],
    queryFn: async () => getApiData(`/strata/surveys/respondents/company/${survey.company_id}`),
    initialData: [],
  });

  // Get all groups with the min number of respondents for the assessment
  const { data: groups } = useQuery<Group[]>({
    queryKey: ["groups", survey.company_id],
    queryFn: async () => getApiData(`/strata/companies/groups/${survey.company_id}`),
    enabled: !!survey.company_id,
    initialData: [],
  });


  const { data: surveyKeys } = useQuery<SurveyKey[]>({
    queryKey: ["surveyKeys", surveyId],
    queryFn: async () => getApiData(`/strata/surveys/surveykeys/${surveyId}`),
    enabled: !!surveyId,
    initialData: [],
  });

  // get strata job titles
  const { data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", survey.company_id],
    queryFn: async () => getApiData(`/strata/companies/strata-job-titles/${survey.company_id}`),
    enabled: !!survey.company_id,
    initialData: [],
  });

  const respondents = allRespondents.filter((res) => res.survey_id === surveyId);

  // filter surveys that are not yet closed
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  // cutoff values
  const UPPER_CUTOFF = 66.66; // above red because mental health value
  const LOWER_CUTOFF = 33.33; // below green because mental health value

  // get count of users in each category for latest survey
  const red = respondents.filter((r) => parseFloat(r.mental_health_value) >= UPPER_CUTOFF).length;
  const yellow = respondents.filter((r) => parseFloat(r.mental_health_value) > LOWER_CUTOFF && parseFloat(r.mental_health_value) < UPPER_CUTOFF).length;
  const green = respondents.filter((r) => parseFloat(r.mental_health_value) <= LOWER_CUTOFF && parseFloat(r.mental_health_value) > 10).length;
  const gray = respondents.filter((r) => parseFloat(r.mental_health_value) <= 10).length;

  const siValue = calculateSIValue(respondents);

  // calculate datasets for stress category by job title
  let stressCategoryDatasets: Dataset[] = [
    {
      label: "At-risk",
      data: [],
      color: colors.red[400],
    },
    {
      label: "Impared",
      data: [],
      color: colors.yellow[400],
    },
    {
      label: "Optimized",
      data: [],
      color: colors.green[400],
    },
  ];

 // loop over completed surveys
 for (let i = 0; i < completedSurveys.length; i++) {
  // for each survey, get total counts of red, yellow, green
  let redCountForSurvey = 0;
  let yellowCountForSurvey = 0;
  let greenCountForSurvey = 0;
  const respondentsForSuvey = allRespondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
  for (let respondent of respondentsForSuvey) {
    if (showCustomGroups && selectedGroupFilters.length > 0 && !selectedGroupFilters.some((filter) => respondent.group_ids.includes(filter))) {
      continue; // skip for group filters
    }
    if (!showCustomGroups && selectedStrataJobTitleFilters.length > 0 && (!respondent.strata_job_title_id || !selectedStrataJobTitleFilters.includes(respondent.strata_job_title_id))) {
      continue; // skip for strata job title filters
    }

    const mentalHealthValue = parseFloat(respondent.mental_health_value);
    if (mentalHealthValue > UPPER_CUTOFF) {
      redCountForSurvey++;
    } else if (mentalHealthValue > LOWER_CUTOFF) {
      yellowCountForSurvey++;
    } else {
      greenCountForSurvey++;
    }
  }
  stressCategoryDatasets[0].data.push({ value: redCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Red
  stressCategoryDatasets[1].data.push({ value: yellowCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Yellow
  stressCategoryDatasets[2].data.push({ value: greenCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Green
}
  // broken down by groups (grouped bar)
  const latestCompletedGroupCounts: { name: string; red: number; yellow: number; green: number }[] = [];

  // broken down by strata job titles (grouped bar)
  const latestCompletedStrataJobTitleCounts: { name: string; red: number; yellow: number; green: number }[] = [];

  if (respondents) {
    groups.forEach((group) => {
      const respondentsInGroup = respondents.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
      let redCount = 0;
      let yellowCount = 0;
      let greenCount = 0;
      respondentsInGroup.forEach((r) => {
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        } else {
          greenCount++;
        }
      });

      latestCompletedGroupCounts.push({
        name: group.group_name,
        red: redCount,
        yellow: yellowCount,
        green: greenCount,
      });
    });

    for (const strataJobTitle of strataJobTitles) {
      const respondentsWithStrataJobTitle = respondents.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
      let redCount = 0;
      let yellowCount = 0;
      let greenCount = 0;
      for (const respondent of respondentsWithStrataJobTitle) {
        const mentalHealthValue = parseFloat(respondent.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        } else {
          greenCount++;
        }
      }
      if (redCount + yellowCount + greenCount >= 5) {
        latestCompletedStrataJobTitleCounts.push({
          name: strataJobTitle.strata_job_title_name,
          red: redCount,
          yellow: yellowCount,
          green: greenCount,
        });
      }
    }
  }

  // group impaired
  let groupImpairedTrendDatasets: Dataset[] = groups.map((group) => {
    return { label: group.group_name ?? "Not Found", data: [] };
  });

  // impaired counts for each month
  for (let i = 0; i < completedSurveys.length; i++) {
    const groupsObject: any = {};
    const respondentsForSuvey = allRespondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
    groups.forEach((group) => {
      const respondentsInGroup = respondentsForSuvey.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
      let redCount = 0;
      let yellowCount = 0;
      respondentsInGroup.forEach((r) => {
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        }
      });

      const dataset = groupImpairedTrendDatasets.find((ds) => ds.label === group.group_name);
      if (dataset) {
        dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
      }
    });

    Object.values(groupsObject).forEach((g: any) => {
      const dataset = groupImpairedTrendDatasets.find((d) => d.label === g.name);
      if (dataset) {
        dataset.data[i] = { value: g.count, date: new Date(completedSurveys[i].start_date) };
      }
    });
  }

  // impared counts for each month
  if (completedSurveys) {
    for (let i = 0; i < completedSurveys.length; i++) {
      const groupsObject: any = {};
      const respondentsForSuvey = allRespondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
      respondentsForSuvey.forEach((r) => {
        if (r.strata_job_title_id) {
          const mentalHealthValue = parseFloat(r.mental_health_value);
          if (!groupsObject[r.strata_job_title_id]) {
            groupsObject[r.strata_job_title_id] = { name: r.strata_job_title_name };
          }
          if (mentalHealthValue > 33.33) {
            groupsObject[r.strata_job_title_id].count ? groupsObject[r.strata_job_title_id].count++ : (groupsObject[r.strata_job_title_id].count = 1);
          }
        }
      });

      Object.values(groupsObject).forEach((g: any) => {
        const dataset = groupImpairedTrendDatasets.find((d) => d.label === g.name);
        if (dataset) {
          dataset.data[i] = { value: g.count, date: new Date(completedSurveys[i].start_date) };
        }
      });
    }
  }

  // strata job title impaired
  let strataJobTitleImpairedTrendDatasets: Dataset[] = strataJobTitles.map((jobTitle) => {
    return { label: jobTitle.strata_job_title_name ?? "Not Found", data: [] };
  });

  // impaired counts for each month
  for (let i = 0; i < completedSurveys.length; i++) {
    const strataJobTitlesObject: any = {};
    const respondentsForSuvey = allRespondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);

    for (const strataJobTitle of strataJobTitles) {
      const respondentsWithStrataJobTitle = respondentsForSuvey.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
      let redCount = 0;
      let yellowCount = 0;
      respondentsWithStrataJobTitle.forEach((r) => {
        const mentalHealthValue = parseFloat(r.mental_health_value);
        if (mentalHealthValue > UPPER_CUTOFF) {
          redCount++;
        } else if (mentalHealthValue > LOWER_CUTOFF) {
          yellowCount++;
        }
      });

      const dataset = strataJobTitleImpairedTrendDatasets.find((ds) => ds.label === strataJobTitle.strata_job_title_name);
      if (dataset) {
        dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
      }
    }

    Object.values(strataJobTitlesObject).forEach((jt: any) => {
      const dataset = strataJobTitleImpairedTrendDatasets.find((d) => d.label === jt.name);
      if (dataset) {
        dataset.data[i] = { value: jt.count, date: new Date(completedSurveys[i].start_date) };
      }
    });
  }

  const groupDropdownOptions = ["Custom Groups", "Clinical Groups"];

  if (allRespondentsIsPending) {
    return <Spinner />;
  }
  if (!surveyId) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0"></div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">No assessment</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>You have not ran an assessment yet. Come back after first assessment has been closed</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="">
        <div className="text-blue-500 text-lg mb-4">
          SI Value: {siValue.toFixed(2)}
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <RespondentCategory responseRate={(respondents.length / surveyKeys.length) * 100} green={green} yellow={yellow} red={red} gray={gray} />

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">How is Your Team Experiencing Stress</h2>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution respondents={respondents} />
        </Container>

        {latestCompletedGroupCounts.length > 0 && (
          <div>
            <div className="mb-4 mt-12">
              <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Group Stress Breakdown</h2>
            </div>

            <Container title="" className="col-span-3 mb-4 h-96">
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-5"
              />
              <GroupCategoryComparison groupCounts={showCustomGroups ? latestCompletedGroupCounts : latestCompletedStrataJobTitleCounts} />
            </Container>
          </div>
        )}

        <div className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Stress Trend Over Time</h2>
        </div>

        <div className="mb-6 grid grid-cols-5 gap-4">
          <Container title="Number in Red, Yellow, and Green Over Time" className={`lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative`}>
            <TrendChart datasets={stressCategoryDatasets} stepSize={1} suggestedMin={0} suggestedMax={5} calcType="add" />
          </Container>

          <Container title="Filter" className="lg:col-span-1 md:col-span-2 col-span-5 w-full h-96 lg:px-5">
            <Dropdown
              options={groupDropdownOptions}
              selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
              setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
              className="mb-2 mt-2 z-20"
            />
            <div className="h-[265px] overflow-scroll pl-1">
              {showCustomGroups
                ? groups.map((group) => {
                    return (
                      <fieldset key={group.group_id} className="mb-2">
                        <legend className="sr-only">{group.group_name}</legend>
                        <div className="space-y-5">
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id={group.group_id?.toString()}
                                aria-describedby={`${group.group_name}`}
                                name={group.group_name}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                                onChange={(e) => handleCheckboxChange(e)}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label htmlFor={group.group_id?.toString()} className="font-medium text-gray-900">
                                {group.group_name}
                              </label>{" "}
                              <span id={`${group.group_name}-description`} className="text-gray-500">
                                <span className="sr-only">{group.group_name}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    );
                  })
                : strataJobTitles.map((strataJobTitle) => (
                    <fieldset key={strataJobTitle.strata_job_title_id} className="mt-4 mb-2">
                      <legend className="sr-only">{strataJobTitle.strata_job_title_name}</legend>
                      <div className="space-y-5">
                        <div className="relative flex items-start">
                          <div className="flex h-6 items-center">
                            <input
                              id={strataJobTitle.strata_job_title_id?.toString()}
                              aria-describedby={`${strataJobTitle.strata_job_title_name}`}
                              name={strataJobTitle.strata_job_title_name}
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor={strataJobTitle.strata_job_title_id?.toString()} className="font-medium text-gray-900">
                              {strataJobTitle.strata_job_title_name}
                            </label>{" "}
                            <span id={`${strataJobTitle.strata_job_title_name}-description`} className="text-gray-500">
                              <span className="sr-only">{strataJobTitle.strata_job_title_name}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  ))}
            </div>
          </Container>
        </div>

        {groups && groups.length > 0 && (
          <div>
            <div className="mb-4 mt-12">
              <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Groups Experiencing the Highest Stress Levels</h2>
            </div>
            <Container title="Groups in the Red and Yellow" className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 mt-2 z-20 flex"
              />
              <TrendChart datasets={showCustomGroups ? groupImpairedTrendDatasets : strataJobTitleImpairedTrendDatasets} suggestedMax={5} suggestedMin={0} calcType="add" height={"80%"} />
            </Container>
          </div>
        )}
      </div>
    );
  }
}
