import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../libs/helpers";

export default function UsersTableWithPagination(props: { users: Array<any>; limit: number; surveyKeys: Array<any> }) {
  const { users, surveyKeys, limit } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentUsers, setCurrentUsers] = useState<Array<any>>([]);
  const range = (start: number, end: number) => {
    //@ts-ignore
    return [...Array(end).keys()].map((el) => el + start);
  };

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
  }

  const start = (currentPage - 1) * limit + 1;
  const end = currentPage * limit <= users.length ? currentPage * limit : users.length;
  const pagesCount = Math.ceil(users.length / limit);
  const pages = range(1, pagesCount);

  useEffect(() => {
    setCurrentUsers(users.slice(start - 1, end));
  }, [start, end, currentPage]);

  return (
    <div className="">
      <div className="mt-1">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
        </div>
        <div className="flex flex-col">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Phone Number
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Facility Title
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Strata Intel Title
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Taken?
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentUsers?.map((user: any, index: number) => (
                    <tr key={index} className={surveyKeys.find((sk) => sk.user_id === user.user_id && sk.is_taken) ? "bg-green-50" : ""}>
                      <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8")}>
                        {user.email}
                      </td>
                      <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8")}>
                        {formatPhoneNumber(user.phone_number?.slice(-10))}
                      </td>
                      <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell")}>
                        {user.company_job_title_name}
                      </td>
                      <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell")}>
                        {user.strata_job_title_name ? (
                          user.strata_job_title_name
                        ) : (
                          <Link to={`/company/${user.company_id}?tab=jobMapping`}>
                            <span className="text-red-600 text-xs underline">No Mapping Assigned</span>
                          </Link>
                        )}
                      </td>
                      <td className={classNames(index !== users.length - 1 ? "border-b border-gray-200" : "", "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell")}>
                        {surveyKeys.find((sk) => sk.user_id === user.user_id && sk.is_taken) ? <span className="text-green-600">Yes</span> : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between bg-white py-3">
        <div className="flex flex-1 justify-between sm:hidden">
          <a href="#" className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Previous
          </a>
          <a href="#" className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
            Next
          </a>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between overflow-scroll">
          <div>
          {users.length > 0 ? (
                <div className="text-sm">
                  Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of <span className="font-medium">{users.length}</span> users
                </div>
              ) : (
                <div>No users</div>
              )}
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm overflow-scroll" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                disabled={currentPage === 1}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
              {pages.map((page) => (
                <button
                  key={page}
                  aria-current="page"
                  onClick={() => setCurrentPage(page)}
                  className={
                    page === currentPage
                      ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                      : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                  }
                  disabled={page === currentPage}
                >
                  {page}
                </button>
              ))}
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                disabled={currentPage === pages.length}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
